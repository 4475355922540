//--------------------------------------------------
//Sidebar for the application on desktop view
//--------------------------------------------------

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faSquareFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import { Container, Row } from 'react-bootstrap';

import me from '../../Images/Ho2025.jpg'

const Sidebar = ({ scrollToRef, aboutRef, projectRef, resumeRef }) => {
    return (
        <Container className='side-bar'>
            <div className='side-bar-components'>
                <img src={me}></img>
                <h1>JC Ho</h1>
                <h3>Web Developer | Software Developer</h3>
                <hr />
                <h2 onClick={() => scrollToRef(aboutRef)}>About Me</h2>
                <h2 onClick={() => scrollToRef(projectRef)}>Projects</h2>
                <h2 onClick={() => scrollToRef(resumeRef)}>Resume</h2>
                <hr />
                <h4>Contact Me</h4>
                <p><a href={`tel:+12085078846`}>(208) 507-8846</a></p>
                <p><a href="mailto: jcrho3005@gmail.com">jcrho3005@gmail.com</a></p>
                <div className='icons'>
                    <Link to='https://www.linkedin.com/in/jcrho/' target='_blank'>
                        <span><FontAwesomeIcon icon={faLinkedin} /></span>
                    </Link>
                    <Link to='https://www.facebook.com/heck.heck.334/' target='_blank'>
                        <span><FontAwesomeIcon icon={faSquareFacebook} /></span>
                    </Link>
                    <Link to='https://github.com/leiadog11' target='_blank'>
                        <span><FontAwesomeIcon icon={faGithub} /></span>
                    </Link>
                </div>
                <h3>Last Updated: 3/31/2025</h3>
            </div>
        </Container>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(Sidebar);
export { connection as Sidebar };