//--------------------------------------------------
//Modal popup for projects
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Carousel } from 'react-bootstrap';

import AWS from '../../Images/SkillsIcons/AWS.png'
import CSharp from '../../Images/SkillsIcons/CSharp.png'
import CSS from '../../Images/SkillsIcons/CSS.png'
import HTML from '../../Images/SkillsIcons/HTML.png'
import Java from '../../Images/SkillsIcons/Java.png'
import JavaScript from '../../Images/SkillsIcons/JavaScript.png'
import MySQL from '../../Images/SkillsIcons/MySQL.png'
import Node from '../../Images/SkillsIcons/Node.png'
import ReactLogo from '../../Images/SkillsIcons/React.png'
import Rust from '../../Images/SkillsIcons/Rust.png'
import Unity from '../../Images/SkillsIcons/Unity.svg'
import Unreal from '../../Images/SkillsIcons/Unreal.svg'

// TEMPLATE
// { name: '', role: '', description: '', takeaway: '', link: '', skills: [] },

let descriptions = [
    { name: 'Treasure Valley Water Hub', role: 'Full Stack Developer', 
        description: 'A website developed in React.js to bring all Treasure Valley water data, tools, and maps into one place for easy accessibility and use. The project was about 3 months long over the summer and included a Unity Diorama as well that I embedded into the website. I was a main web developer who mainly did front-end and a few back-end functions. I also used many different node.js packages such as React Leaflet fordata visualizations. It includes many maps displaying large amounts of locational data.', 
        takeaway: 'This project was a great experience for me in dealing with clients that didn’t quite know what they wanted. We had a large time of 3 months, but really, this project was completed in about a month, since it took 2 to scope them down into a projec they actually wanted us to work on. There were many hurdles, but I am definitely proud of the product and I learned a lot.',
        link: 'https://treasurevalleywater.org/', skills: [ HTML, CSS, JavaScript, Node, ReactLogo, MySQL ]
    },
    { name: 'IdaGem', role: 'Back-End Developer', 
        description: 'A learning website with a Unity game attached for students in Idaho. The goal of the project is to teach students more about Idaho History, such as the Anne Frank Memorial and the importance of it. I joined as a back-end developer after the project had been completed. My goal was to help clean up and improve many functions on the site, one being to create an S3 bucket on AWS for file uploads. I have completely redone the database structure and structure of most data since a lot of data was being stored as JSON in the database. This has been cleaned up and improved, and I am very proud of that.', 
        takeaway: `It was a great learning experience about working on a project that isn’t mine that was difficult to navigate. A lot of the code and the way the website was structured was hard to edit, which helped me realize how important CI/CD is for any type of project.`,
        link: 'https://idagem.org/', skills: [ AWS, JavaScript, Node, ReactLogo, MySQL ]
    },
    { name: 'Venture College', role: 'Back-End Developer', 
        description: 'A website developed for judges to be able to have a place to leave scores and comments on groups and teams in large competitions. On this project I updated and added back-end features to allow for logos to be added to cards. I also created an AWS S3 Bucket for file storage and back-end systems to connect to it.', 
        takeaway: 'I feel like this is a strong project to showcase as I have kind of specialized in file systems on websites and back-end functions dealing with file systems. My tasks for this project was completed in less than a days work, showing that I am able to learn quick and reapply that knowledge to other areas.', 
        link: 'https://boisestatepitchcompetitions.com/', skills: [ AWS, MySQL, JavaScript, ReactLogo, Node] },
    { name: 'F.A.T.E.', role: 'Front End Web Developer', 
        description: 'A website developed for professors to rate other professors’ portfolios among other statistics to view and use. I shadowed Alex Spoon near the end of this Websites creation to learn React and general web development practices used in the Development Team.', 
        takeaway: 'I created a few components for the site and implemented some functions to see how many professors had reviewed a certain portfolio. This was mainly a learning project for me, but also a great project to look back on and reflect upon. Alex Spoon was a great mentor to me, although brief, he taught me a lot. I believe structure wise, this website is the best from GIMMWorks, and I hope to top it in my future work. It should be the standard.', 
        link: '', skills: [ HTML, CSS, JavaScript, Node, ReactLogo ] 
    },
    { name: 'Agent 13', role: 'Solo Unity VR Dev', 
        description: 'A Unity VR Game developed entirely by me. The premises of the game is a stealth game with combat aspects. The game was made for a class project that encompassed an entire year. For this project, I made a ton of assets in Maya. I then textured them in Substance Painter 3D. I made 3 levels for this game which was 3 floor plans in this large "building" for the game. I also had a professor record around 30 lines of dialogue for the game, which was the "guy in the chair" to help you through your mission. The enemy AI uses State Machines when they are walking around and also in combat.', 
        takeaway: 'I am proud of how the game came out and taught me a lot about game development in Unity and game development in VR. VR development is quite different than just regular Unity 3D development, which I found intertesting. The main difference is that testing is a lot harder, but also just the way that things interact with the VR player are very unique as well.', 
        link: 'https://github.com/leiadog11/Agent-13', skills: [ Unity, CSharp ], video: 'https://youtu.be/3_IecVpGBtg' 
    },
    { name: 'Drum Duel', role: 'Unreal Engine Dev', 
        description: 'Drum Duel is an arcade local multiplayer game made in Unreal Engine. In the game, 2 players compete against each other to create a beat on a drum, and then the other player has to repeat that rhythm back as they remember it. The two take turns doing this until the end. Whoever has the most points from doing the best, wins! We created a special controller for this project, as the game was created for our co-client who was completely blind. The controller aimed to help her navigate the menu as well as play the game, without having to see at all. I was a programmer for this and basically programmed the essence of music into nodes in Unreal Engine, such as beat keeping, and being able to track if a player is on beat or not.', 
        takeaway: 'This was one of my first projects in Unreal Engine, so I was learning a lot about what I could do and how I could do it with the node based system. Also, this was the first time working with a client that needed an accomodation, so it was interesting and fun being able to take a dive into their world and add features you wouldnt usually think of. The controller uses an arduino as well, which talks to Unreal Engine. I created the controller from wood, a couple buttons, and a frisbee golf frisbees as the drum pads. I could see this game in an arcade when fully finished, that hopefully anybody, regardless of disability, could play!', 
        link: 'https://github.com/leiadog11/DrumDuel', skills: [ Unreal ]
    },
    { name: `Slurgin N' Snop`, role: 'Unreal Engine Dev', 
        description: "Slurgin N' Snop is a networked multiplayer game made in Unreal Engine about a Snail and a Slug that wish they had each others traits. They go on an adventure to work together to make it to a fairy garden, but upon making it, they realize that they only could've made it if it weren't for their own personal abilities. I was a programmer for this project, and I did all of the mechanics for the snail. That includes movement, which has snapping to any rotated surface (as any good snail would), being able to climb walls with a wall climb stamina UI bar, being able to go into his shell and roll down slopes, if rolling fast enough, the snail will break through walls, and finally, being able to shell bash while in the shell on a flat surface.", 
        takeaway: "This was one of my first projects in Unreal Engine, so having to program a full movement system, and one that wasn't all that ordinary, since it was for a snail, was quite tricky. There are still bugs with it, but it was a great learning experience for me. If I were to work on an Unreal Engine game again, I definitely would not want to work on any 3D movement scripts again.", 
        link: 'https://github.com/leiadog11/SlurginNSnop', skills: [ Unreal ]
    },
    { name: 'ULT MINUS', role: 'Dev Lead', 
        description: `ULT MINUS is a mod very heavily inspired by Brawl Minus for Smash Bros Brawl. I wanted to play something like that mod, but for the Smash Ultimate. Since it didn't exist, the only thing I could do was make it myself, so I did. I had never touched Rust before or ever made a mod for a game, so this was a large self driven learning experience that I have had a ton of fun with.`,
        takeaway: 'This was about a year of learning and now a year of development. It is a fun project to work on in my spare time, and I always love seeing the reactions of Smash Community members and also people that just know the game casually. The mod still has a long ways to go to be truly polished and unique, but there are aspects of it that I am proud of. In general, I am just proud of myself for being able to have a personal hobby that invovles programming and smash at the same time, and that I am able to keep it up outside of work and school.',
        link: 'https://github.com/leiadog11/ULT-MINUS', skills: [ Rust ]
    },
]

const ProjectModal = ({ name, images, video, show, handleClose }) => {
    return (
        <Fragment>
            <Modal className='project-modal' show={show} onHide={handleClose} size="lg">
                <Modal.Dialog className='project-modal-dialog'>
                    <Modal.Header className='project-modal-header' closeButton>
                        <Modal.Title className='project-modal-title'>{name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='project-modal-body'>
                        {video ? 
                            <iframe className='project-video' src={video} allowFullScreen></iframe>
                        :
                            <Carousel className='project-image-carousel' indicators={false} controls={false} fade>
                                {images.map((img, index) => (
                                    <Carousel.Item interval={2000}>
                                        <img className='project-image' src={img} key={index}></img>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        }
                        {descriptions.map((project, index) => (
                            project.name === name ?
                            <div>
                                <h2 className='project-role'>Role</h2>
                                <h2>{project.role}</h2>
                                <p className='project-description'>{project.description}</p>
                                <p className='project-takeaway'>{project.takeaway}</p>
                                <a className='project-link' href={project.link} target='_blank'>{project.link}</a>
                                <h2 className='project-role'>Skills</h2>
                                <div className='project-skills-container'>
                                    <div className='project-skills'>
                                        {project.skills.map((skill, index) => (
                                            <img className='project-skill' src={skill} key={index}></img>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            : <></>
                        ))}
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ProjectModal);
export { connection as ProjectModal };